<template>
  <main class="main main--login">

    <svg class="login-decor" width="381" height="554" viewBox="0 0 381 554" fill="none">
      <path d="M775.104 253.809C743.421 306.124 569.433 665.538 274.613 519.089C-80.302 342.789 -1.23159 -198.544 30.4518 -250.859C62.1351 -303.173 415.47 36.0026 482.734 76.7401C549.998 117.478 806.788 201.495 775.104 253.809Z" fill="url(#paint0_linear_53_3)"/>
      <defs>
        <linearGradient id="paint0_linear_53_3" x1="530.106" y1="44.9405" x2="456.971" y2="520.672" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFF500"/>
          <stop offset="1" stop-color="#F34E39"/>
        </linearGradient>
      </defs>
    </svg>

    <section class="login-content">
      <div class="container login-content__container">
        <h1 class="title-main title-l login-content__title">
          Login Information
        </h1>
        <div class="login-info">
            <div class="login-info__inner">
              <div class="login-info__image-box">
                <img class="login-info__img" src="~@/assets/img/login-image.svg" alt="login content image">
              </div>
              <div class="login-info__main">
                <div class="login-info__top">
                  <p class="login-info__text">
                    <span class="login-info__block-text">Dear user!</span>
                    During registration, an email was sent to your Email with a link to your clinic:
                  </p>
                  <p class="login-info__text">
                    <span class="login-info__block-text">
                      <strong>For example:</strong> yourclinicname.ferretid.com
                    </span>
                    Please check your inbox and use this link to enter the clinic.
                  </p>
                </div>
                <form class="login-form">
                  <h2 class="login-form__title">
                    Get your access link:
                  </h2>
                  <div class="login-form__inner">
                    <div class="login-form__field">
                      <input class="main-input login-form__input" id="email" type="email" placeholder="Your email" required>
                    </div>
                    <button class="login-form__btn btn" type="submit">
                      Get a Link
                    </button>
                  </div>
                </form>
                <div class="login-info__contacts">
                  <p class="login-info__contacts-text">
                    If you are having difficulty regaining access, please contact us:
                  </p>
                  <p class="login-info__contacts-text">
                    Email:
                    <a class="login-info__mail" href="mailto:info@needplex.com">
                      info@needplex.com
                    </a>
                    or Phone:
                    <a class="login-info__phone" href="tel:+380933829009">
                      +38 (093) 382 90 09
                    </a>
                  </p>
                </div>
              </div>
            </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "v-login"
}
</script>

<style scoped>

</style>