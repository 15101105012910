<template>
  <header class="v-header header">
    <div class="container header__container">
      <b-navbar>
        <template #brand>
          <v-logo></v-logo>
        </template>

        <template #end>
          <b-navbar-item class="nav__link nav__link--active" href="#">
            main
          </b-navbar-item>
          <b-navbar-item class="nav__link" href="#">
            pricing
          </b-navbar-item>
          <b-navbar-item class="nav__link" href="#">
            blog
          </b-navbar-item>
          <router-link class="nav__link" :to="{name: 'contact'}">
            contact us
          </router-link>
          <b-navbar-item class="navbar-item nav__right" tag="div">
            <div class="nav__right-inner buttons">
              <router-link class="nav__link nav__link--primary" :to="{name: 'sign-up'}">
                <strong>sign up</strong>
              </router-link>
              <template>
                <b-dropdown class="header__dropdown" v-model="isLanguage" aria-role="list">
                  <template v-if="isLanguage"
                            #trigger>
                    <b-button
                        class="header__dropdown-button"
                        label="EN"
                    />
                  </template>

                  <template v-else #trigger>
                    <b-button
                        class="header__dropdown-button"
                        label="UA"
                    />
                  </template>

                  <b-dropdown-item  :value="true" aria-role="listitem">
                    <div class="media">
                      <div class="media-content">
                        <h3>EN</h3>
                      </div>
                    </div>
                  </b-dropdown-item>

                  <b-dropdown-item :value="false" aria-role="listitem">
                    <div class="media">
                      <div class="media-content">
                        <h3>UA</h3>
                      </div>
                    </div>
                  </b-dropdown-item>

                </b-dropdown>
              </template>
            </div>
          </b-navbar-item>
        </template>
      </b-navbar>
    </div>
  </header>
</template>

<script>

import vLogo from "../partials/v-logo";

export default {
  name: "v-header",
  components: {
    vLogo,
  },
  data() {
    return {
      isLanguage: true,
    }
  },
  props: {},
}
</script>

<style scoped lang="scss">
  .header--active {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    background-color: #fff;
    box-shadow: 4px 4px 40px 1px rgba(160, 161, 168, 0.25);
  }

  .navbar-item {
    padding: 0;
  }

  .buttons {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .buttons .button {
    margin-bottom: 0;
  }


</style>