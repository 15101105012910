import Vue from 'vue';
import Router from 'vue-router';
import vHome from '../components/pages/v-home';
import vContactUs from '../components/pages/v-contact-us';
import vLogin from '../components/pages/v-login';
import vSignUp from '../components/pages/v-sign-up';

Vue.use(Router);

let router = new Router({
        routes: [
            {
                path: '/',
                name: 'home',
                component: vHome,
                props: true,
            },
            {
                path: '/contact-us',
                name: 'contact',
                component: vContactUs,
                props: true,
            },
            {
                path: '/login',
                name: 'login',
                component: vLogin,
                props: true,
            },
            {
                path: '/sign-up',
                name: 'sign-up',
                component: vSignUp,
                props: true,
            },
        ],
});


export default router;