<template>
<div>
  <main class="main main--home">

    <svg class="home-decor" width="470" height="1034" viewBox="0 0 470 1034" fill="none">
      <path d="M696.5 416C696.5 682.754 672.417 1034 499 1034C325.583 1034 0 618.754 0 352C0 85.2465 209.083 -67 382.5 -67C555.917 -67 696.5 149.246 696.5 416Z" fill="url(#paint0_linear_1_5)"/>
      <defs>
        <linearGradient id="paint0_linear_1_5" x1="449.5" y1="-41" x2="322" y2="928" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFF500"/>
          <stop offset="1" stop-color="#F34E39"/>
        </linearGradient>
      </defs>
    </svg>

    <section class="hero main-pb">
      <div class="container hero__container">
        <div class="hero__box-image">
          <img src="~@/assets/img/hero-image.png" alt="hero content image">
        </div>
        <div class="hero__content">
          <h1 class="title-main title-xl hero__title">
            Cloud App for Veterinary Clinic
          </h1>
          <ul class="hero-list">
            <li class="hero-list__item">
              Keep track of your customers and pets
            </li>
            <li class="hero-list__item">
              Select roles for your employees
            </li>
            <li class="hero-list__item">
              Manage products
            </li>
          </ul>
          <a class="btn hero__link" href="#">
            Get a Demo
          </a>
        </div>
      </div>
    </section>

    <section class="benefits main-pb">
      <div class="container benefits__container">
        <h2 class="title-main title-l title-mb benefits__title">
          Benefits
        </h2>
        <p class="subtitle-main benefits__subtitle main-mb">
          Continuously improving system benefits and functionality
        </p>
        <ul class="benefits-list grid">
          <li class="benefits-list__item">
            <div class="benefits-list__icon">
              <svg  width="44" height="44" viewBox="0 0 44 44" fill="none">
                <path d="M22 1.83325L5.5 9.16658V20.1666C5.5 30.3416 12.54 39.8566 22 42.1666C31.46 39.8566 38.5 30.3416 38.5 20.1666V9.16658L22 1.83325ZM22 21.9816H34.8333C33.8617 29.5349 28.82 36.2633 22 38.3716V21.9999H9.16667V11.5499L22 5.84825V21.9816Z" fill="url(#paint0_linear_1_49)"/>
                <defs>
                  <linearGradient id="paint0_linear_1_49" x1="26.7972" y1="2.78572" x2="23.161" y2="38.5281" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFF500"/>
                    <stop offset="1" stop-color="#F34E39"/>
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div class="benefits-list__item-wrapper">
              <h3 class="title-main benefits-list__title">
                Reliability
              </h3>
              <p class="benefits-list__description">
                We provide security for storing your data
              </p>
            </div>
          </li>
          <li class="benefits-list__item">
            <div class="benefits-list__icon">
              <svg  width="44" height="44" viewBox="0 0 44 44" fill="none">
                <path d="M21.0833 3.66675C12.485 3.66675 5.5 10.6517 5.5 19.2501C5.5 27.8484 12.485 34.8334 21.0833 34.8334H22V40.3334C30.91 36.0434 36.6667 27.5001 36.6667 19.2501C36.6667 10.6517 29.6817 3.66675 21.0833 3.66675ZM22.9167 30.2501H19.25V26.5834H22.9167V30.2501ZM22.9167 23.8334H19.25C19.25 17.8751 24.75 18.3334 24.75 14.6667C24.75 12.6501 23.1 11.0001 21.0833 11.0001C19.0667 11.0001 17.4167 12.6501 17.4167 14.6667H13.75C13.75 10.6151 17.0317 7.33341 21.0833 7.33341C25.135 7.33341 28.4167 10.6151 28.4167 14.6667C28.4167 19.2501 22.9167 19.7084 22.9167 23.8334Z" fill="black"/>
                <path d="M21.0833 3.66675C12.485 3.66675 5.5 10.6517 5.5 19.2501C5.5 27.8484 12.485 34.8334 21.0833 34.8334H22V40.3334C30.91 36.0434 36.6667 27.5001 36.6667 19.2501C36.6667 10.6517 29.6817 3.66675 21.0833 3.66675ZM22.9167 30.2501H19.25V26.5834H22.9167V30.2501ZM22.9167 23.8334H19.25C19.25 17.8751 24.75 18.3334 24.75 14.6667C24.75 12.6501 23.1 11.0001 21.0833 11.0001C19.0667 11.0001 17.4167 12.6501 17.4167 14.6667H13.75C13.75 10.6151 17.0317 7.33341 21.0833 7.33341C25.135 7.33341 28.4167 10.6151 28.4167 14.6667C28.4167 19.2501 22.9167 19.7084 22.9167 23.8334Z" fill="url(#paint0_linear_1_58)"/>
                <defs>
                  <linearGradient id="paint0_linear_1_58" x1="25.614" y1="4.53263" x2="22.4297" y2="37.0502" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFF500"/>
                    <stop offset="1" stop-color="#F34E39"/>
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div class="benefits-list__item-wrapper">
              <h3 class="title-main benefits-list__title">
                Support
              </h3>
              <p class="benefits-list__description">
                Sustained support and training is the basis of cooperation for us
              </p>
            </div>
          </li>
          <li class="benefits-list__item">
            <div class="benefits-list__icon">
              <svg  width="44" height="44" viewBox="0 0 44 44" fill="none">
                <path d="M9.625 41.25C8.26526 41.25 6.93605 40.8468 5.80546 40.0914C4.67487 39.3359 3.79368 38.2622 3.27333 37.006C2.75298 35.7497 2.61683 34.3674 2.8821 33.0338C3.14738 31.7001 3.80216 30.4751 4.76364 29.5136C5.72513 28.5522 6.95014 27.8974 8.28376 27.6321C9.61738 27.3668 10.9997 27.503 12.256 28.0233C13.5122 28.5437 14.5859 29.4249 15.3414 30.5555C16.0968 31.686 16.5 33.0153 16.5 34.375C16.4978 36.1977 15.7728 37.9451 14.484 39.234C13.1951 40.5228 11.4477 41.2478 9.625 41.25ZM9.625 30.25C8.80916 30.25 8.01163 30.4919 7.33328 30.9452C6.65492 31.3985 6.12621 32.0427 5.814 32.7964C5.50179 33.5502 5.4201 34.3796 5.57926 35.1798C5.73843 35.9799 6.1313 36.7149 6.70819 37.2918C7.28508 37.8687 8.02008 38.2616 8.82026 38.4207C9.62043 38.5799 10.4498 38.4982 11.2036 38.186C11.9573 37.8738 12.6016 37.3451 13.0548 36.6667C13.5081 35.9884 13.75 35.1909 13.75 34.375C13.7489 33.2813 13.314 32.2327 12.5406 31.4594C11.7673 30.686 10.7187 30.2511 9.625 30.25Z" fill="url(#paint0_linear_1_67)"/>
                <path d="M33 35.75H19.25V33H33V23.375H11C10.2709 23.3743 9.57183 23.0843 9.05626 22.5687C8.54069 22.0532 8.25073 21.3541 8.25 20.625V11C8.25073 10.2709 8.54069 9.57183 9.05626 9.05626C9.57183 8.54069 10.2709 8.25073 11 8.25H24.75V11H11V20.625H33C33.7291 20.6257 34.4282 20.9157 34.9437 21.4313C35.4593 21.9468 35.7493 22.6459 35.75 23.375V33C35.7493 33.7291 35.4593 34.4282 34.9437 34.9437C34.4282 35.4593 33.7291 35.7493 33 35.75Z" fill="url(#paint1_linear_1_67)"/>
                <path d="M38.5 16.5H30.25C29.5209 16.4993 28.8218 16.2093 28.3063 15.6937C27.7907 15.1782 27.5007 14.4791 27.5 13.75V5.5C27.5007 4.77088 27.7907 4.07183 28.3063 3.55626C28.8218 3.04069 29.5209 2.75073 30.25 2.75H38.5C39.2291 2.75073 39.9282 3.04069 40.4437 3.55626C40.9593 4.07183 41.2493 4.77088 41.25 5.5V13.75C41.2493 14.4791 40.9593 15.1782 40.4437 15.6937C39.9282 16.2093 39.2291 16.4993 38.5 16.5ZM30.25 5.5V13.75H38.5V5.5H30.25Z" fill="url(#paint2_linear_1_67)"/>
                <defs>
                  <linearGradient id="paint0_linear_1_67" x1="11.6238" y1="27.8247" x2="10.6061" y2="40.051" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFF500"/>
                    <stop offset="1" stop-color="#F34E39"/>
                  </linearGradient>
                  <linearGradient id="paint1_linear_1_67" x1="25.9977" y1="8.89941" x2="23.9623" y2="33.352" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFF500"/>
                    <stop offset="1" stop-color="#F34E39"/>
                  </linearGradient>
                  <linearGradient id="paint2_linear_1_67" x1="36.3738" y1="3.0747" x2="35.3561" y2="15.301" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFF500"/>
                    <stop offset="1" stop-color="#F34E39"/>
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div class="benefits-list__item-wrapper">
              <h3 class="title-main benefits-list__title">
                Data
              </h3>
              <p class="benefits-list__description">
                We will transfer your data to the new system without loss
              </p>
            </div>
          </li>
          <li class="benefits-list__item">
            <div class="benefits-list__icon">
              <svg  width="44" height="44" viewBox="0 0 44 44" fill="none">
                <path d="M3.66659 38.5C3.14714 38.5 2.71203 38.324 2.36125 37.972C2.00925 37.6212 1.83325 37.1861 1.83325 36.6667C1.83325 36.1472 2.00925 35.7121 2.36125 35.3613C2.71203 35.0093 3.14714 34.8333 3.66659 34.8333H40.3333C40.8527 34.8333 41.2878 35.0093 41.6386 35.3613C41.9906 35.7121 42.1666 36.1472 42.1666 36.6667C42.1666 37.1861 41.9906 37.6212 41.6386 37.972C41.2878 38.324 40.8527 38.5 40.3333 38.5H3.66659ZM7.33325 33C6.32492 33 5.46203 32.6413 4.74459 31.9238C4.02592 31.2052 3.66659 30.3417 3.66659 29.3333V9.16667C3.66659 8.15833 4.02592 7.29483 4.74459 6.57617C5.46203 5.85872 6.32492 5.5 7.33325 5.5H36.6666C37.6749 5.5 38.5384 5.85872 39.2571 6.57617C39.9745 7.29483 40.3333 8.15833 40.3333 9.16667V29.3333C40.3333 30.3417 39.9745 31.2052 39.2571 31.9238C38.5384 32.6413 37.6749 33 36.6666 33H7.33325ZM7.33325 29.3333H36.6666V9.16667H7.33325V29.3333ZM7.33325 29.3333V9.16667V29.3333Z" fill="url(#paint0_linear_1_78)"/>
                <defs>
                  <linearGradient id="paint0_linear_1_78" x1="27.8632" y1="6.27929" x2="25.8602" y2="35.6893" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFF500"/>
                    <stop offset="1" stop-color="#F34E39"/>
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div class="benefits-list__item-wrapper">
              <h3 class="title-main benefits-list__title">
                Development
              </h3>
              <p class="benefits-list__description">
                Every day we improve the system and introduce new features
              </p>
            </div>
          </li>
          <li class="benefits-list__item">
            <div class="benefits-list__icon">
              <svg  width="44" height="44" viewBox="0 0 44 44" fill="none">
                <path d="M23.3199 19.7999C22.4399 19.5799 21.5599 19.1399 20.8999 18.4799C20.2399 18.2599 20.0199 17.5999 20.0199 17.1599C20.0199 16.7199 20.2399 16.0599 20.6799 15.8399C21.3399 15.3999 21.9999 14.9599 22.6599 15.1799C23.9799 15.1799 25.0799 15.8399 25.7399 16.7199L27.7199 14.0799C27.0599 13.4199 26.3999 12.9799 25.7399 12.5399C25.0799 12.0999 24.1999 11.8799 23.3199 11.8799V8.7999H20.6799V11.8799C19.5799 12.0999 18.4799 12.7599 17.5999 13.6399C16.7199 14.7399 16.0599 16.0599 16.2799 17.3799C16.2799 18.6999 16.7199 20.0199 17.5999 20.8999C18.6999 21.9999 20.2399 22.6599 21.5599 23.3199C22.2199 23.5399 23.0999 23.9799 23.7599 24.4199C24.1999 24.8599 24.4199 25.5199 24.4199 26.1799C24.4199 26.8399 24.1999 27.4999 23.7599 28.1599C23.0999 28.8199 22.2199 29.0399 21.5599 29.0399C20.6799 29.0399 19.5799 28.8199 18.9199 28.1599C18.2599 27.7199 17.5999 27.0599 17.1599 26.3999L14.9599 28.8199C15.6199 29.6999 16.2799 30.3599 17.1599 31.0199C18.2599 31.6799 19.5799 32.3399 20.8999 32.3399V35.1999H23.3199V31.8999C24.6399 31.6799 25.7399 31.0199 26.6199 30.1399C27.7199 29.0399 28.3799 27.2799 28.3799 25.7399C28.3799 24.4199 27.9399 22.8799 26.8399 21.9999C25.7399 20.8999 24.6399 20.2399 23.3199 19.7999ZM21.9999 4.3999C12.3199 4.3999 4.3999 12.3199 4.3999 21.9999C4.3999 31.6799 12.3199 39.5999 21.9999 39.5999C31.6799 39.5999 39.5999 31.6799 39.5999 21.9999C39.5999 12.3199 31.6799 4.3999 21.9999 4.3999ZM21.9999 37.1799C13.6399 37.1799 6.8199 30.3599 6.8199 21.9999C6.8199 13.6399 13.6399 6.8199 21.9999 6.8199C30.3599 6.8199 37.1799 13.6399 37.1799 21.9999C37.1799 30.3599 30.3599 37.1799 21.9999 37.1799Z" fill="url(#paint0_linear_1_87)"/>
                <defs>
                  <linearGradient id="paint0_linear_1_87" x1="27.1169" y1="5.23115" x2="24.5116" y2="36.5305" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFF500"/>
                    <stop offset="1" stop-color="#F34E39"/>
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div class="benefits-list__item-wrapper">
              <h3 class="title-main benefits-list__title">
                Cost
              </h3>
              <p class="benefits-list__description">
                The cost is formed separately for each user of the system
              </p>
            </div>
          </li>
          <li class="benefits-list__item">
            <div class="benefits-list__icon">
              <svg  width="44" height="44" viewBox="0 0 44 44" fill="none">
                <path d="M8.03818 9.03833C6.33313 10.7385 4.98084 12.7588 4.059 14.9832C3.13715 17.2075 2.66393 19.5922 2.66651 22C2.66651 27.06 4.71985 31.6433 8.03818 34.9617L10.6232 32.3767C9.25654 31.0173 8.17356 29.4001 7.43711 27.6187C6.70066 25.8374 6.32542 23.9275 6.33318 22C6.33318 17.9483 7.96485 14.2633 10.6232 11.6233L8.03818 9.03833ZM33.9615 9.03833L31.3765 11.6233C32.7418 12.9837 33.8238 14.6012 34.5602 16.3823C35.2965 18.1633 35.6725 20.0727 35.6665 22C35.6665 26.07 34.0348 29.7367 31.3765 32.3767L33.9615 34.9617C35.6666 33.2615 37.0189 31.2412 37.9407 29.0168C38.8625 26.7924 39.3358 24.4078 39.3332 22C39.3332 16.94 37.2798 12.3567 33.9615 9.03833ZM13.2265 14.2267C12.2031 15.2459 11.3913 16.4574 10.8375 17.7914C10.2838 19.1254 9.99912 20.5556 9.99985 22C9.99985 25.025 11.2282 27.775 13.2265 29.7733L15.8115 27.1883C15.1299 26.5074 14.5895 25.6985 14.2214 24.8081C13.8533 23.9178 13.6648 22.9635 13.6665 22C13.6665 19.965 14.4915 18.1317 15.8115 16.8117L13.2265 14.2267ZM28.7732 14.2267L26.1882 16.8117C26.8698 17.4926 27.4101 18.3015 27.7782 19.1919C28.1464 20.0822 28.3349 21.0365 28.3332 22C28.3332 24.035 27.5082 25.8683 26.1882 27.1883L28.7732 29.7733C29.7965 28.7541 30.6084 27.5426 31.1622 26.2086C31.7159 24.8746 32.0006 23.4444 31.9998 22C31.9998 18.975 30.7715 16.225 28.7732 14.2267ZM20.9998 18.3333C20.0274 18.3333 19.0948 18.7196 18.4071 19.4073C17.7195 20.0949 17.3332 21.0275 17.3332 22C17.3332 22.9725 17.7195 23.9051 18.4071 24.5927C19.0948 25.2804 20.0274 25.6667 20.9998 25.6667C21.9723 25.6667 22.9049 25.2804 23.5926 24.5927C24.2802 23.9051 24.6665 22.9725 24.6665 22C24.6665 21.0275 24.2802 20.0949 23.5926 19.4073C22.9049 18.7196 21.9723 18.3333 20.9998 18.3333Z" fill="url(#paint0_linear_1_96)"/>
                <defs>
                  <linearGradient id="paint0_linear_1_96" x1="26.3301" y1="9.65051" x2="24.9689" y2="32.7808" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFF500"/>
                    <stop offset="1" stop-color="#F34E39"/>
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div class="benefits-list__item-wrapper">
              <h3 class="title-main benefits-list__title">
                Mobility
              </h3>
              <p class="benefits-list__description">
                You get full access to clinic data from anywhere in the world
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <section class="presentation main-pb">
      <div class="container presentation__container">
        <h2 class="title-main title-l title-mb presentation__title">
          Presentation
        </h2>
        <p class="subtitle-main presentation__subtitle main-mb">
          You can check our presentation with some images of the CRM.
          In addition, you can request a demo and try it for free
        </p>
        <div class="presentation__image-box">
          <img src="~@/assets/img/presentation-image.jpg" alt="presentation content image">
        </div>
        <a class="btn presentation__link" href="#">
          Get a Demo
        </a>
      </div>
    </section>

    <section class="features  main-pb">
      <div class="container features__container">
        <h2 class="title-main title-l title-mb features__title">
          Features
        </h2>
        <p class="subtitle-main features__subtitle main-mb">
          Features implemented in this version: v.2.0
        </p>
        <ul class="features-list grid main-mb">
          <li class="features-list__item">
            <div class="features-list__item-wrapper">
              <h3 class="features-list__title">Languages</h3>
              <ul class="features-sublist">
                <li class="features-sublist__item">
                  Ukrainian;
                </li>
                <li class="features-sublist__item">
                  English;
                </li>
                <li class="features-sublist__item">
                  Russian.
                </li>
              </ul>
            </div>
          </li>
          <li class="features-list__item">
            <div class="features-list__item-wrapper">
              <h3 class="features-list__title">Employees</h3>
              <ul class="features-sublist">
                <li class="features-sublist__item">
                  Activation of certain employees;
                </li>
                <li class="features-sublist__item">
                  Role selection for a specific employee;
                </li>
                <li class="features-sublist__item">
                  Unlimited possibilities.
                </li>
              </ul>
            </div>
          </li>
          <li class="features-list__item">
            <div class="features-list__item-wrapper">
              <h3 class="features-list__title">Owners</h3>
              <ul class="features-sublist">
                <li class="features-sublist__item">
                  Manage owners;
                </li>
                <li class="features-sublist__item">
                  Accounting and storage of data by owners;
                </li>
                <li class="features-sublist__item">
                  Comments, notes.
                </li>
              </ul>
            </div>
          </li>
          <li class="features-list__item">
            <div class="features-list__item-wrapper">
              <h3 class="features-list__title">Patients</h3>
              <ul class="features-sublist">
                <li class="features-sublist__item">
                  Patient management;
                </li>
                <li class="features-sublist__item">
                  Accounting and storage of data;
                </li>
                <li class="features-sublist__item">
                  Comments, notes.
                </li>
              </ul>
            </div>
          </li>
          <li class="features-list__item">
            <div class="features-list__item-wrapper">
              <h3 class="features-list__title">Visits</h3>
              <ul class="features-sublist">
                <li class="features-sublist__item">
                  Creating a patient appointment;
                </li>
                <li class="features-sublist__item">
                  Accounting visits for each patient;
                </li>
                <li class="features-sublist__item">
                  Detail card, recommendations.
                </li>
              </ul>
            </div>
          </li>
          <li class="features-list__item">
            <div class="features-list__item-wrapper">
              <h3 class="features-list__title">Services</h3>
              <ul class="features-sublist">
                <li class="features-sublist__item">
                  Creating clinic services;
                </li>
                <li class="features-sublist__item">
                  Group by category;
                </li>
                <li class="features-sublist__item">
                  Accounting for each move.
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <a class="btn features__link" href="#">
          More
        </a>
      </div>
    </section>

    <section class="pricing main-pb">
      <div class="container pricing__container">
        <h2 class="title-main title-l title-mb pricing__title">
          Pricing
        </h2>
        <p class="subtitle-main pricing__subtitle main-mb">
          Best price for all-in-one solution
        </p>
        <div class="pricing__card">
          <div class="pricing__card-img">
            <img src="~@/assets/img/pricing-image.svg" alt="pricing image">
          </div>
          <span class="pricing__card-price">$10 / month</span>
          <span class="pricing__card-for">per veterinarian</span>
          <a class="btn pricing__card-link" href="#">
            Get Started
          </a>
          <p class="pricing__card-start">
            Start your 14-days trial
          </p>
        </div>
      </div>
    </section>

    <section class="faq main-pb">
      <div class="container faq__container">
        <h2 class="title-main title-l title-mb faq__title">
          Pricing
        </h2>
        <p class="subtitle-main faq__subtitle main-mb">
          Best price for all-in-one solution
        </p>

        <div class="accordion">
          <b-collapse
              class="accordion__item"
              animation="slide"
              v-for="(collapse, index) of collapses"
              :key="index"
              :open="isOpen === index"
              @open="isOpen = index"
              :aria-id="'contentIdForA11y5-' + index">
            <template #trigger="props">
              <div
                  class="accordion__item-top"
                  role="button"
                  :aria-controls="'contentIdForA11y5-' + index"
                  :aria-expanded="props.open"
              >
                <h3 class="accordion__item-title title-main">
                  {{ collapse.title }}
                </h3>
                <svg width="8" height="5" viewBox="0 0 8 5" fill="none">
                  <path d="M0.710051 1.71L3.30005 4.3C3.69005 4.69 4.32005 4.69 4.71005 4.3L7.30005 1.71C7.93005 1.08 7.48005 0 6.59005 0H1.41005C0.520051 0 0.0800515 1.08 0.710051 1.71Z" fill="#273238"/>
                </svg>
              </div>
            </template>
            <div class="accordion__item-content">
              <h4 class="accordion__content-title title-main">
                {{ collapse.titleContent }}
              </h4>
              <ul class="accordion__list">
                <li class="accordion__list-item">
                  The tariff is offered for 6 months.
                </li>
                <li class="accordion__list-item">
                  You can renew the system after 6 months.
                </li>
              </ul>
            </div>
          </b-collapse>
        </div>
      </div>
    </section>

    <section class="get-a-demo main-pb">
        <div class="container get-a-demo__container">
          <h2 class="get-a-demo__title title-l title-main">
            Ready to get a Demo?
          </h2>
          <div class="get-a-demo__inner">
            <div class="get-a-demo__column get-a-demo__column--main">
              <form action="#" class="main-form">
                <div class="main-form__inner">
                  <div class="main-form__field main-form__field--half">
                    <input class="main-form__input main-input" id="first_name" type="text" required>
                    <label class="main-form__label" for="first_name">
                      First Name*
                    </label>
                  </div>
                  <div class="main-form__field main-form__field--half">
                    <input class="main-form__input main-input" id="last_name" type="text" required>
                    <label class="main-form__label" for="last_name">
                      Last Name*
                    </label>
                  </div>
                </div>
                <div class="main-form__field">
                  <input class="main-form__input main-input" id="company" type="text">
                  <label class="main-form__label" for="company">
                    Company Name
                  </label>
                </div>
                <div class="main-form__inner">
                  <div class="main-form__field main-form__field--half">
                    <input class="main-form__input main-input" id="email" type="email" required>
                    <label class="main-form__label" for="email">
                      E-mail*
                    </label>
                  </div>
                  <div class="main-form__field main-form__field--half">
                    <input class="main-form__input main-input" id="phone" type="tel" required>
                    <label class="main-form__label" for="phone">
                      Phone Number*
                    </label>
                  </div>
                </div>
                <div class="main-form__field">
                  <textarea class="main-form__text main-textarea" id="question" name="question" rows="4" cols="20">
                  </textarea>
                  <label class="main-form__label" for="question">
                    Please, tell us about you and your team
                  </label>
                </div>
                <button class="btn main-form__btn" type="submit">Get a Demo</button>
              </form>
            </div>
            <div class="get-a-demo__slider-column">
              <b-carousel
                  class="get-a-demo__slider main-slider"
                  :arrow="arrow"
                  :arrow-hover="arrowHover"
                  :icon-pack="iconPack"
                  :icon-prev="iconPrev"
                  :icon-next="iconNext"
                  :indicator ='indicator'
              >
                <b-carousel-item class="main-slider__item" v-for="(carousel, i) in carousels" :key="i">
                  <div class="main-slider__item-inner">
                    <div class="main-slider__wrapper-img">
                      <img :src="carousel.image" alt="slider image">
                    </div>
                    <span class="main-slider__name">{{carousel.name}}</span>
                    <span class="main-slider__position">{{carousel.position}}</span>
                  </div>
                </b-carousel-item>
              </b-carousel>
            </div>
          </div>
        </div>
    </section>

  </main>
</div>
</template>

<script>


export default {
  name: "v-home",
  components: {},
  data() {
    return {
      isOpen: 0,
      arrow: true,
      arrowHover: false,
      iconPack: 'mdi',
      iconPrev: 'arrow-left',
      iconNext: 'arrow-right',
      indicator: false,
      collapses: [
        {
          title: 'What is the cost of the plans?',
          titleContent: 'What period is offered?',
        },
        {
          title: 'How much does it cost to change the system for us?',
          titleContent: 'What period is offered?',
        },
        {
          title: 'How to start using the system?',
          titleContent: 'What period is offered?',
        },
        {
          title: 'How to extend the plan?',
          titleContent: 'What period is offered?',
        },
        {
          title: 'How can I get help?',
          titleContent: 'What period is offered?',
        },
      ],
      carousels: [
        {
          name: 'Julian Nagelsmann',
          position: 'Customer Support',
          image: `https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80`,
        },
        {
          name: 'Domenico Tedesco',
          position: 'Customer Support',
          image: `https://images.unsplash.com/photo-1528892952291-009c663ce843?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=644&q=80`,
        },
        {
          name: 'Lauren Sesselmann',
          position: 'Customer Support',
          image: `https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80`,
        }
      ]
    }
  },
}
</script>

<style scoped>

</style>