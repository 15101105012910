<template>

  <main class="main main--sign">
    <div class="sign-top-decor">
      <svg  width="381" height="554" viewBox="0 0 381 554" fill="none">
        <path d="M775.104 253.809C743.421 306.124 569.433 665.538 274.613 519.089C-80.302 342.789 -1.23159 -198.544 30.4518 -250.859C62.1351 -303.173 415.47 36.0026 482.734 76.7401C549.998 117.478 806.788 201.495 775.104 253.809Z" fill="url(#paint0_linear_53_3)"/>
        <defs>
          <linearGradient id="paint0_linear_53_3" x1="530.106" y1="44.9405" x2="456.971" y2="520.672" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FFF500"/>
            <stop offset="1" stop-color="#F34E39"/>
          </linearGradient>
        </defs>
      </svg>
    </div>

    <div class="sign-bottom-decor">
      <svg width="528" height="1112" viewBox="0 0 528 1112" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M244.932 1260.15C226.217 1389.65 -226.505 1300.93 -340.923 1284.39C-455.342 1267.86 -268.906 786.055 -250.191 656.557C-231.477 527.058 -118.336 -14.5427 -3.91781 1.99255C51.8831 10.0567 468.191 -57.822 521.374 243.964C577.242 560.985 254.519 1193.8 244.932 1260.15Z" fill="url(#paint0_linear_1_623)"/>
        <defs>
          <linearGradient id="paint0_linear_1_623" x1="319.104" y1="1016.84" x2="-400.201" y2="499.27" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FFF500"/>
            <stop offset="1" stop-color="#F34E39"/>
          </linearGradient>
        </defs>
      </svg>
    </div>

    <section class="sign-content main-pb">
      <div class="container sign-content__container">
        <h1 class="title-main title-l sign-content__title">
          Sign Up to FerretID
        </h1>
        <div class="sign-item">
          <form class="sign-form main-form">
            <div class="main-form__field">
              <v-select
                :options="options"
                @select="optionSelect"
                :selected="selected"
                :icon="icon"
                id="language"
              />
              <label class="main-form__label" for="language">
                Please, choose the language for your system*
              </label>
            </div>
            <div class="main-form__field">
              <input class="main-form__input main-input" id="full-name" type="text" required>
              <label class="main-form__label" for="full-name">
                First and Last Name*
              </label>
            </div>
            <div class="main-form__field">
              <input class="main-form__input main-input" id="company" type="text" required>
              <label class="main-form__label" for="company">
                Company Name*
              </label>
            </div>
            <div class="main-form__inner">
              <div class="main-form__field main-form__field--third">
                <input class="main-form__input main-input" id="domain" type="email" required>
                <label class="main-form__label" for="domain">
                  Domain (in latin)*
                </label>
                <span class="main-form__disabled-text">
                  ferretid.com
                </span>
              </div>
            </div>
            <div class="main-form__inner">
              <div class="main-form__field main-form__field--half">
                <input class="main-form__input main-input" id="email" type="email" required>
                <label class="main-form__label" for="email">
                  E-mail*
                </label>
              </div>
              <div class="main-form__field main-form__field--half">
                <input class="main-form__input main-input" id="phone" type="tel" required>
                <label class="main-form__label" for="phone">
                  Phone Number*
                </label>
              </div>
            </div>
            <div class="main-form__inner">
              <div class="main-form__field main-form__field--half">
                <input class="main-form__input main-input" id="password" type="password" required>
                <label class="main-form__label" for="password">
                  Password*
                </label>
              </div>
              <div class="main-form__field main-form__field--half">
                <input class="main-form__input main-input" id="confirm-pass" type="password" required>
                <label class="main-form__label" for="confirm-pass">
                  Confirm Password*
                </label>
              </div>
            </div>
            <div class="main-form__field">
              <label class="main-form__checkbox">
                <input type="checkbox">
                <span class="main-form__checkbox-icon"></span>
                <span class="main-form__checkbox-descr">
                  I agree with privacy policy and terms of use.
                </span>
              </label>
            </div>
            <p class="main-form__description">
              If you already have an account, you can log in
              <router-link class="main-form__link" :to="{name: 'login'}">
                log in
              </router-link>
            </p>
            <button class="main-form__btn btn">
              Sign Up
            </button>
          </form>
          <p class="sign-item__description">
            Or if you need some kind of help,
            you may request a demo
          </p>
          <a class="sign-item__btn btn" href="#">Get a Demo</a>
        </div>
      </div>
    </section>
  </main>

</template>

<script>

import vSelect from "../partials/v-select";
export default {
  name: "v-sign-up",
  components: {
    vSelect
  },
  data() {
    return {
      options: [
        {name: 'English', icon: `${require('@/assets/img/icons/britain-icon.svg')}`,  value: 1},
        {name: 'Ukrainian', icon: `${require('@/assets/img/icons/ukraine-icon.png')}`,  value: 2},
        {name: 'Russian', icon: ``,  value: 3}
      ],
      selected: 'English',
      icon: `${require('@/assets/img/icons/britain-icon.svg')}`
    }
  },
  methods: {
    optionSelect(option) {
      this.selected = option.name
      this.icon = option.icon
    }
  }
}
</script>

<style scoped>

</style>