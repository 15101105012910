<template>
  <main class="main main--contact">
    <svg class="contact-decor" width="2012" height="692" viewBox="0 0 2012 692" fill="none">
      <path d="M2011.75 252.312C2011.75 278.184 724.37 691.249 552.043 691.249C379.717 691.249 0 463.218 0 437.346C0 411.474 199.458 0.750977 371.784 0.750977C544.111 0.750977 2011.75 226.44 2011.75 252.312Z" fill="url(#paint0_linear_1_473)"/>
      <defs>
        <linearGradient id="paint0_linear_1_473" x1="1298.32" y1="17.057" x2="1280.67" y2="634.788" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFF500"/>
          <stop offset="1" stop-color="#F34E39"/>
        </linearGradient>
      </defs>
    </svg>

    <section class="hero-contact main-pb">
      <div class="container hero-contact__container">
        <div class="hero-contact__content">
          <h1 class="title-main title-xl hero-contact__title">
            Contact us
          </h1>
          <p class="main-subtitle hero-contact__subtitle">
            We are always open to dialogue and focused on results
          </p>
        </div>
        <a class="btn hero-contact__link">
          Get Info
        </a>
      </div>
    </section>


    <section class="question main-pb">
      <div class="container question__container">
        <h2 class="question__title title-l title-main">
          Do you have any questions?
        </h2>
        <div class="question__inner">
          <div class="question__main">
            <form action="#" class="main-form">
              <div class="main-form__inner">
                <div class="main-form__field main-form__field--half">
                  <input class="main-form__input main-input" id="first_name" type="text" required>
                  <label class="main-form__label" for="first_name">
                    First Name*
                  </label>
                </div>
                <div class="main-form__field main-form__field--half">
                  <input class="main-form__input main-input" id="last_name" type="text" required>
                  <label class="main-form__label" for="last_name">
                    Last Name*
                  </label>
                </div>
              </div>
              <div class="main-form__field">
                <input class="main-form__input main-input" id="company" type="text">
                <label class="main-form__label" for="company">
                  Company Name
                </label>
              </div>
              <div class="main-form__inner">
                <div class="main-form__field main-form__field--half">
                  <input class="main-form__input main-input" id="email" type="email" required>
                  <label class="main-form__label" for="email">
                    E-mail*
                  </label>
                </div>
                <div class="main-form__field main-form__field--half">
                  <input class="main-form__input main-input" id="phone" type="tel" required>
                  <label class="main-form__label" for="phone">
                    Phone Number*
                  </label>
                </div>
              </div>
              <div class="main-form__field">
                  <textarea class="main-form__text main-textarea" id="question" name="question" rows="4" cols="20">
                  </textarea>
                <label class="main-form__label" for="question">
                  Question
                </label>
              </div>
              <button class="btn main-form__btn question__form-btn" type="submit">Send</button>
            </form>
          </div>

          <div class="question__info">
            <h3 class="question__info-title">
              Veterinary service Ferret ID
            </h3>
            <p class="question__info-descr">
              We welcome your suggestions, comments and cooperation.
            </p>
            <a class="question__info-phone" href="tel:+380933829009">
              +38 (093) 382 90 09
            </a>
            <a class="question__info-mail" href="mailto:info@ferretid.com">
              info@ferretid.com
            </a>
          </div>
        </div>
      </div>
    </section>



  </main>
</template>

<script>
export default {
  name: "contact-us"
}
</script>

<style scoped>

</style>