<template>
  <div class="v-main-wrapper wrapper">
    <v-header></v-header>
    <router-view class="main-content"></router-view>
    <v-footer></v-footer>
  </div>
</template>

<script>

import vHeader from './layouts/v-header';
import vFooter from './layouts/v-footer';

  export default {
    name: "v-main-wrapper",
    components: {
      vHeader,
      vFooter
    },
  }
</script>

<style scoped>

</style>