<template>
  <div class="main-select">

    <p
      class="main-select__label"
      @click="optionsVisible = !optionsVisible"
    >
      <img class="main-select__icon" :src="icon" alt="option icon">
      <span class="main-select__value">{{selected}}</span>
    </p>

    <div class="main-select__options"
      v-show="optionsVisible"
    >
      <p
          class="main-select__option"
          v-for="option in options"
          :key="option.value"
          @click="selectOption(option)"
      >
        <img class="main-select__icon" :src="option.icon" alt="option icon">
        <span class="main-select__value">{{option.name}}</span>
      </p>
    </div>

  </div>
</template>

<script>
export default {
  name: "v-select",
  props: {
    options: {
      type: Array,
      default() {
        return []
      },
    },
    selected: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      optionsVisible: false,
    }
  },
  methods: {
    selectOption(option) {
      this.$emit('select', option)
      this.optionsVisible = false
    }
  }
}
</script>

<style scoped>
  .main-select p {
    margin: 0;
  }

  .options {
    border: 1px solid #273238;
  }
</style>