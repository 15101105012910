import Vue from 'vue';
import App from './App.vue'
import Buefy from 'buefy'
import router from './router/router';
import "./assets/scss/app.scss";
Vue.use(Buefy)

new Vue({
    render: h => h(App),
    router,
}).$mount('#app')
